<template>
  <list-select
      ref="select"
      async
      :emit-only-value="false"
      v-on:input="(v) => $emit('input', v)"
      :search-data="search"
      v-model="model"
      :parse-callback="parse"
      enable-photo
      enable-profile
  />
</template>

<script>
import ListSelect from "@/reuse/input/ListSelect"
import {listProject as list} from "@/domain/project/services"
export default {
  name: "ProjectSelect",
  props: {
    value: {
      required: true
    }
  },
  data () {
    return {
      model: this.value,
      result: []
    }
  },
  watch: {
    value (v) {
      this.$nextTick(() => {
        if (v !== this.model) {
          this.$refs.select.autoSelectValue(v)
        }
      })
    }
  },
  components: {ListSelect},
  methods: {
    search (terms) {
      console.log('Find', this.model)
      const filtros = []
      if (terms) {
        filtros.push('search=' + terms)
      }
      filtros.push('active=1')
      return new Promise((resolve, reject) => {
        list(100, 1, '&' + filtros.join('&'))
            .then(response => {
              this.result = response.data.result
              let list = response.data.result.map((obj) => {
                return {
                  ...obj,
                  photo: obj.image,
                  value: obj.id,
                  label: obj.name
                }
              }).sort((a, b) => {
                if (a.name > b.name) {
                  return 1;
                }
                if (a.name < b.name) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              })
              resolve(list)
            })
            .catch(({response}) => {
              reject(response)
            })
      })
    },
    parse (v) {
      if (v && v.name) {
        return v.name
      }
      return v
    }
  }
}
</script>
