var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.prevent)?_c('div',{staticClass:"simple-menu m-t-xs flex justify-between column full-width e-input-modern size1"},[_c('div',{staticClass:"stask-search stask-search-suporte"},[_c('e-row',{attrs:{"mr":""}},[(!_vm.disable || !_vm.disable.internalStatus)?_c('e-col',{staticStyle:{"max-width":"200px"}},[_c('erp-s-field',{attrs:{"label":"Situação"}},[_c('u-select',{staticClass:"no-box-shadow",attrs:{"placeholder":"Aberta ou fechada","hide-underline":"","inverted-light":"","multiple":"","options":[
        {label: 'Abertas', value: 'opened'},
        {label: 'Fechadas', value: 'closed'},
        ]},model:{value:(_vm.filters.internalStatus),callback:function ($$v) {_vm.$set(_vm.filters, "internalStatus", $$v)},expression:"filters.internalStatus"}})],1)],1):_vm._e(),_c('e-col',[_c('erp-s-field',{attrs:{"label":"Tipo"}},[_c('type-select',{attrs:{"placeholder":"Qualquer"},model:{value:(_vm.filters.type),callback:function ($$v) {_vm.$set(_vm.filters, "type", $$v)},expression:"filters.type"}})],1)],1),_c('e-col',[_c('erp-s-field',{attrs:{"required":"","label":"Fila"}},[_c('task-queue-select',{attrs:{"with-no-project":"","show-privates":""},model:{value:(_vm.filters.queue),callback:function ($$v) {_vm.$set(_vm.filters, "queue", $$v)},expression:"filters.queue"}})],1)],1),_c('e-col',[_c('erp-s-field',{attrs:{"label":"Prioridade"}},[_c('priority-select',{model:{value:(_vm.filters.priority),callback:function ($$v) {_vm.$set(_vm.filters, "priority", $$v)},expression:"filters.priority"}})],1)],1),_c('e-col',{staticStyle:{"min-width":"150px"}},[_c('date-input',{attrs:{"label":"Data (De)"},model:{value:(_vm.filters.data1),callback:function ($$v) {_vm.$set(_vm.filters, "data1", $$v)},expression:"filters.data1"}})],1),_c('e-col',{staticStyle:{"min-width":"150px"}},[_c('date-input',{attrs:{"label":"Data (Até)"},model:{value:(_vm.filters.data2),callback:function ($$v) {_vm.$set(_vm.filters, "data2", $$v)},expression:"filters.data2"}})],1),_c('e-col',{staticStyle:{"min-width":"150px"}},[_c('erp-s-field',{attrs:{"label":"Filtro Data"}},[_c('u-select',{staticClass:"no-box-shadow",attrs:{"hide-underline":"","inverted-light":"","options":[
                    {label: 'Criação', value: 'createdAt'},
                    {label: 'Início', value: 'startTime'},
                    {label: 'Prazo', value: 'deadline'},
                ]},model:{value:(_vm.filters.tipoData),callback:function ($$v) {_vm.$set(_vm.filters, "tipoData", $$v)},expression:"filters.tipoData"}})],1)],1)],1),_c('e-row',{attrs:{"mr":""}}),_c('e-row',{attrs:{"mr":""}},[_c('e-col',[_c('erp-s-field',{attrs:{"label":"Relator"}},[_c('person-select',{model:{value:(_vm.filters.author),callback:function ($$v) {_vm.$set(_vm.filters, "author", $$v)},expression:"filters.author"}})],1)],1),_c('e-col',[_c('erp-s-field',{attrs:{"label":"Responsável"}},[_c('person-select',{model:{value:(_vm.filters.assigned),callback:function ($$v) {_vm.$set(_vm.filters, "assigned", $$v)},expression:"filters.assigned"}})],1)],1),_c('e-col',[_c('erp-s-field',{attrs:{"label":"Projeto"}},[_c('project-select',{model:{value:(_vm.filters.project),callback:function ($$v) {_vm.$set(_vm.filters, "project", $$v)},expression:"filters.project"}})],1)],1),_c('e-col',[_c('erp-s-field',{attrs:{"label":"Cliente"}},[_c('person-select',{model:{value:(_vm.filters.cliente),callback:function ($$v) {_vm.$set(_vm.filters, "cliente", $$v)},expression:"filters.cliente"}})],1)],1)],1)],1),_c('e-row',{staticClass:"m-t",attrs:{"mr":""}},[_c('e-col',{staticClass:"text-right"},[_c('a',{staticClass:"m-r font-12 text-negative",on:{"click":_vm.reset}},[_vm._v("Remover filtros")]),_c('e-btn',{attrs:{"label":"Buscar"},on:{"click":_vm.buscarFechar}})],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }